export const Caret = require('./icons/caret.ico');
export const CommuterLogo = require('./images/commuter.png');
export const CommuterBanner = require('./images/commuterBusBanner.png')
export const InstagramLogoOrange = require('./images/instagram-orange.png');
export const InstagramLogoBlue = require('./images/instagram-blue.png');
export const DiscordLogo = require('./images/discord.png');
export const LinkedInLogo = require('./images/linkedin.png');
export const YoutubeLogo = require('./images/youtube.png');
export const LinktreeLogo = require('./images/linktree.png');
export const GithubLogo = require('./images/github.png');
// export const EmailLogo = require('./images/email.png')

export const Akshat = require('./images/TeamImages/Akshat.jpg');
export const Sean = require('./images/TeamImages/Sean.jpg');
export const Sunny = require('./images/TeamImages/Sunny.jpg');
export const Eshaa = require('./images/TeamImages/Eshaa.jpg');
export const Linda = require('./images/TeamImages/Linda.jpg');
export const Viv = require('./images/TeamImages/Viv.jpg');
export const Celin = require('./images/TeamImages/Celin.jpg');
export const David = require('./images/TeamImages/David.jpg');
export const Michael = require('./images/TeamImages/Michael.jpg');
export const Vanda = require('./images/TeamImages/Vanda.jpg');